import "./Divider.scss";
const Divider = () => {
  return (
    <>
      <div className="hero__card-divider"></div>
    </>
  );
};

export default Divider;
