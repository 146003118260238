import React from "react";
import "./App.scss";
import { Home } from "../pages";

const App = () => {
  return (
    <>
      <div className="App">
        <Home />
      </div>
    </>
  );
};

export default App;
